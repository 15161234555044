import axios from 'axios'
import Store from '../../store'
import {
  errorHandler,
  successHandler,
  generateNoOptionsQuery
} from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/transactions`

export default {
  transactions() {
    return {
      addImageToTransaction: async ({ images = [], trxId }) => {
        const endpoint = `${URL}/${trxId}/image`
        const formData = new FormData()
        images.forEach((i) => formData.append('images', i))
        try {
          const res = await axios.put(endpoint, formData, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      // &type=${type}&category=${category}
      // { type, category }
      getAll: async (populate, limit = 0) => {
        const endpoint = `${URL}/user${generateNoOptionsQuery(
          populate,
          limit
        )}&user=${Store.state.user.user._id}`

        // console.log(endpoint)
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getFundTransfer: async (...params) => {
        const endpoint = `${BASEURL}/transfers/user${generateNoOptionsQuery(
          ...params
        )}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
