<template>
  <v-sheet color="white" class="text-center my-2">
    <v-img
      width="245"
      :src="noData"
      alt="no-transaction"
      class="mx-auto"
    ></v-img>
    <p class="mt-4 text-h6">No transaction yet</p>
  </v-sheet>
</template>

<script>
import noData from '../assets/img/no_data.svg'
export default {
  data: () => ({
    noData
  })
}
</script>

<style></style>
