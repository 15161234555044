import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/bill-payment`

export default {
  billApi() {
    return {
      airtime: {
        async getProviders(params = {}) {
          const endpoint = `${URL}/airtime/providers`
          try {
            const res = await axios.get(endpoint, {
              params,
              headers: {
                Authorization: `Bearer ${Store.state.user.token}`
              }
            })
            return successHandler(res)
          } catch (error) {
            errorHandler(error)
          }
        },
        async buyAirtime(data = {}) {
          const endpoint = `${URL}/airtime`
          try {
            const res = await axios.post(endpoint, data, {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        }
      },
      data: {
        async getProviders(params = {}) {
          const endpoint = `${URL}/dataplans/providers`
          try {
            const res = await axios.get(endpoint, {
              params,
              headers: {
                Authorization: `Bearer ${Store.state.user.token}`
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        },
        async buyData(data = {}) {
          const endpoint = `${URL}/dataplan`
          try {
            const res = await axios.post(endpoint, data, {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        },
        async getProvidersPackages(params = {}) {
          const endpoint = `${URL}/dataplans`
          try {
            const res = await axios.get(endpoint, {
              params,
              headers: {
                Authorization: `Bearer ${Store.state.user.token}`
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        }
      },
      betting: {
        getProviders: async (params = {}) => {
          const endpoint = `${URL}/betting/providers`
          try {
            const res = await axios.get(endpoint, {
              params,
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        },
        topUp: async (data = {}) => {
          const endpoint = `${URL}/betting/topup`
          try {
            const res = await axios.post(endpoint, data, {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        }
      },
      cable: {
        getProviders: async (params = {}) => {
          const endpoint = `${URL}/cable/providers`
          try {
            const res = await axios.get(endpoint, {
              params,
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        },
        getProvidersPackages: async (provider) => {
          const endpoint = `${URL}/cable/providers/${provider}/packages`
          try {
            const res = await axios.get(endpoint, {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        },
        topUp: async (data) => {
          const endpoint = `${URL}/cable/topup`
          try {
            const res = await axios.post(endpoint, data, {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        }
      },
      electricity: {
        async getProviders(params = {}) {
          const endpoint = `${URL}/electricity/providers`
          try {
            const res = await axios.get(endpoint, {
              params,
              headers: {
                Authorization: `Bearer ${Store.state.user.token}`
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        },
        async topUp(data = {}) {
          const endpoint = `${URL}/electricity/topup`
          try {
            const res = await axios.post(endpoint, data, {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        },
        async validateMeterNumber(data = {}) {
          const endpoint = `${URL}/electricity/validate`
          try {
            const res = await axios.post(endpoint, data, {
              headers: {
                Authorization: `Bearer ${Store.state.user.token}`
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        }
      },
      airtimeToCash: {
        async calculatePayback(data = {}) {
          const endpoint = `${URL}/airtime-to-cash/calculate-payback`
          try {
            const res = await axios.post(endpoint, data, {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        },
        async getTransferInstructions(params = {}) {
          const endpoint = `${URL}/airtime-to-cash/transfer-instructions`
          try {
            const res = await axios.get(endpoint, {
              params,
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        },
        async sendRequest(data = {}) {
          const endpoint = `${URL}/airtime-to-cash/transfer`
          try {
            const res = await axios.post(endpoint, data, {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        }
      },
      history: {
        getTransaction: async (params = {}) => {
          const endpoint = `${BASEURL}/bill-payment/user`
          try {
            const res = await axios.get(endpoint, {
              params,
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            })
            return successHandler(res)
          } catch (error) {
            return errorHandler(error)
          }
        }
      }
    }
  }
}
